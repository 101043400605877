/* eslint-disable no-nested-ternary */
import React, {Component} from 'react';
import classNames from 'classnames';
import exchangeToken from 'api/exchangeToken';
import updateLogEvent from 'api/updateLogEvent';
import deleteTest from 'api/deleteTest';
import moment from 'moment';
import {Link} from 'react-router-dom';
import strings from 'utils/strings';
import 'moment/locale/nb';

import {
	NOT_STARTED,
	COMPLETED,
	STARTED,
	TEST_TYPES,
	TEST_TYPE_NAMES,
	CHILD_TEST_URL,
	DISTRIBUTED,
} from 'constants/index';

const childTypes = [
	TEST_TYPES.CHILD_FOLLOWUP,
	TEST_TYPES.YOUTH_FOLLOWUP,
	TEST_TYPES.ADULT_FOLLOWUP,
];

const disabledAccess = ['child-followup'];

const screeningTypesExceptChild = [
	TEST_TYPES.ADULT_SCREENING,
	TEST_TYPES.YOUTH_SCREENING,
];
const AllScreeningTypes = [
	TEST_TYPES.ADULT_SCREENING,
	TEST_TYPES.YOUTH_SCREENING,
	TEST_TYPES.CHILD_SCREENING,
];

class TestRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			testResults: this.props.testResults,
			testResult: this.props.testResult,
			processTestResults: this.props.processTestResults,
			visitedLinks: [],
		};
	}
	deleteTest = (e, id) => {
		e.preventDefault();

		deleteTest(id).then(() => {
			if (this.props.onDelete && !this.props.mainPanel) {
				this.props.onDelete();
			}
		});
	};

	handleClick = (id, key) => {
		this.updateViewLog(id, key);
	};

	updateViewLog(id, key) {
		updateLogEvent('view', id, key, 'Results');
	}

	sendCodeUsingEmail = (event, emailAddress) => {
		event.preventDefault();
		const {processTestResults, testResults} = this.props;
		const {id, key: testKey} = this.state.testResult;

		this.props.onDistributeTest(id, testKey, emailAddress);
	};

	componentDidMount() {
		const visitedLinks = localStorage.getItem('visitedLinks');
		if (visitedLinks === null) {
			localStorage.setItem('visitedLinks', JSON.stringify([]));
			this.setState({visitedLinks: []});
		} else {
			this.setState({visitedLinks: JSON.parse(visitedLinks)});
		}
	}

	getTestDateInfo(
		completedAt,
		usedAt,
		distributedAt,
		childSelfStarting,
		pinCode,
		type,
		groupStatus,
		testKey,
		id
	) {
		let testDateInfo;
		if (completedAt) {
			testDateInfo = (
				<td className="lit-test__col">
					<div className="lit-test__field"> </div>{' '}
				</td>
			);
		} else if (usedAt) {
			if (pinCode && AllScreeningTypes.includes(type)) {
				testDateInfo = (
					<td className="lit-test__col">
						<p className="lit-test-no-margin">
							<br />
							<span>Pinkode: </span>
							<b>{pinCode}</b>{' '}
							{/* {moment(distributedAt).format('LLL')}
							&nbsp; */}
						</p>
					</td>
				);
			} else if (childTypes.includes(type)) {
				testDateInfo = this.renderContinueButton(
					true,
					groupStatus,
					type,
					testKey,
					id
				);
			} else {
				testDateInfo = (
					<td className="lit-test__col">
						<div className="lit-test__field"> </div>{' '}
					</td>
				);
			}
		} else if (distributedAt) {
			if (AllScreeningTypes.includes(type) && pinCode) {
				testDateInfo = (
					<td className="lit-test__col">
						<p className="lit-test-no-margin">
							<span>Pinkode: </span>
							<b>{pinCode}</b>
							<br />
							{/* {moment(distributedAt).format('LLL')}
							&nbsp; */}
						</p>
					</td>
				);
			} else {
				testDateInfo = (
					<td className="lit-test__col">
						<div className="lit-test__field"> </div>{' '}
					</td>
				);
			}
		}

		return testDateInfo;
	}

	changeTestOwnerShip(testKey) {
		this.props.onClaimOwnership(testKey);
	}
	renderDeleteButton(
		usedAt,
		completedAt,
		testKey,
		id,
		status,
		groupStatus,
		distributedAt
	) {
		const completed = status === COMPLETED;
		const started = status === STARTED;
		let testDate = null;
		let distributionDate = null;
		const isDistributed = status === DISTRIBUTED;

		if ((completed || started) && !groupStatus) {
			return (
				<td className="lit-test__col">
					<div className="lit-test__field">
						<button
							className="lit-btn lit-btn--small bg-negative2"
							onClick={(e) => {
								if (completedAt) {
									testDate = moment(
										completedAt,
										'YYYY-MM-DD'
									);
								} else {
									testDate = moment(usedAt, 'YYYY-MM-DD');
								}

								const timeDifference = moment().diff(
									testDate,
									'days'
								);

								if (timeDifference > 90) {
									const key = {testKey};
									this.props.onRenderDynamicMessagePane(
										strings.warning_deletetest,
										id,
										key
									);
								} else {
									this.props.onRenderStaticMessagePane(
										strings.message_testcannotbedeleted
									);
								}
							}}
						>
							{strings.delete}
						</button>
					</div>
				</td>
			);
		} else if (status === NOT_STARTED || groupStatus) {
			return null;
		}
		if (isDistributed) {
			return (
				<td className="lit-test__col">
					<div className="lit-test__field">
						<button
							className="lit-btn lit-btn--small bg-negative2"
							onClick={(e) => {
								if (distributedAt) {
									distributionDate = moment(
										distributedAt,
										'YYYY-MM-DD'
									);
									const timeDifference = moment().diff(
										distributionDate,
										'days'
									);
									if (timeDifference > 90) {
										const key = {testKey};
										this.props.onRenderDynamicMessagePane(
											strings.warning_deletetest,
											id,
											key
										);
									} else {
										this.props.onRenderStaticMessagePane(
											strings.message_testcannotbedeleted
										);
									}
								}
							}}
						>
							{strings.delete}
						</button>
					</div>
				</td>
			);
		}

		return (
			<td className="lit-test__col">
				<div className="lit-test__field"> </div>{' '}
			</td>
		);
	}
	renderButton(type, id, status, testKey, groupStatus) {
		const completed = status === COMPLETED;
		const started = status === STARTED;

		const isChildType = childTypes.includes(type);

		const destination = isChildType
			? `/single-child-test/${id}`
			: type === 'child-experimental'
			? `/single-speech-test/${id}`
			: `/single-test/${id}`;

		if (completed || started) {
			return (
				<span className="lit-see-result-fixed-width">
					<Link
						className="lit-btn lit-btn--small bg-secondary"
						to={destination}
						onClick={() => this.handleClick(id, testKey)}
						target="_blank"
						rel="noopener noreferrer"
					>
						{strings.seeresults}
					</Link>
				</span>
			);
		} else if (status === NOT_STARTED && !groupStatus) {
			return (
				<button
					className="lit-btn lit-btn--small bg-negative2"
					disabled={groupStatus}
					onClick={(e) => {
						this.deleteTest(e, id);
					}}
				>
					{strings.delete}
				</button>
			);
		}
		return (
			<span className="lit-test__col">
				<span className="lit-test__field"> </span>{' '}
			</span>
		);
	}

	renderContinueButton(started, groupStatus, type, testKey, id) {
		if (started && childTypes.includes(type)) {
			//Dormant for Now
			return (
				<td className="lit-test__col">
					<button
						className="lit-btn lit-btn--small bg-secondary-green"
						onClick={() => {
							exchangeToken(testKey).then((token) => {
								window.open(
									`${CHILD_TEST_URL}?key=${testKey}&role=admin&token=${token}&type=${type}&state=1`,
									'_blank'
								);
								if (this.props.onStartTest) {
									this.props.onStartTest();
								}
							});
						}}
					>
						{strings.continue}
					</button>
				</td>
			);
		}
	}

	getLabelWithStatus(completedAt, usedAt, distributedAt, status) {
		let testDateInfo;
		if (completedAt) {
			testDateInfo = (
				<td className={'lit-test__col'}>
					<div className={'lit-test-status'}>
						<div
							className={classNames({
								'lit-test-status__indicator': true,
								[status.id]: true,
							})}
						/>
						<span className="lit-test-status__label">
							{status.label}
							<br />
							<span className="lit-test-status__margin">
								{moment(completedAt).locale('nb').format('LLL')}
							</span>
						</span>
					</div>
				</td>
			);
		} else if (usedAt) {
			testDateInfo = (
				<td className={'lit-test__col'}>
					<div className={'lit-test-status'}>
						<div
							className={classNames({
								'lit-test-status__indicator': true,
								[status.id]: true,
							})}
						/>
						<span className="lit-test-status__label">
							{status.label}
							<br />
							<span className="lit-test-status__margin">
								{moment(usedAt).locale('nb').format('LLL')}
							</span>
						</span>
					</div>
				</td>
			);
		} else if (distributedAt) {
			testDateInfo = (
				<td className={'lit-test__col'}>
					<div className={'lit-test-status'}>
						<div
							className={classNames({
								'lit-test-status__indicator': true,
								[status.id]: true,
							})}
						/>
						<span className="lit-test-status__label">
							{status.label}
							<br />
							<span className="lit-test-status__margin">
								{moment(distributedAt)
									.locale('nb')
									.format('LLL')}
							</span>
						</span>
					</div>
				</td>
			);
		} else {
			testDateInfo = (
				<td className={'lit-test__col'}>
					<div className={'lit-test-status'}>
						<div
							className={classNames({
								'lit-test-status__indicator': true,
								[status.id]: true,
							})}
						/>
						<p className="lit-test-status__label">{status.label}</p>
					</div>
				</td>
			);
		}

		return testDateInfo;
	}

	render() {
		const {testResult} = this.props;
		const groupStatus = this.props.groupPanel;
		const {
			id,
			key: testKey,
			testUser,
			status,
			usedAt,
			completedAt,
			distributedAt,
			recipient,
			type,
			pinCode,
			childSelfStarting,
		} = testResult;

		let email;
		let firstName;
		let lastName;
		let fullName;
		if (testUser && testUser.firstName) {
			email = testUser.email;
			firstName = testUser.firstName;
			lastName = testUser.lastName;
			fullName = `${firstName} ${lastName}`;
		} else {
			email = recipient;
		}
		if (
			!fullName &&
			testResult.info &&
			type === 'child-screening' &&
			!childSelfStarting &&
			distributedAt
		) {
			const unParsedinfo = testResult.info;
			const info = JSON.parse(unParsedinfo);
			fullName = `${info.firstName} ${info.lastName}`;
		}
		if (
			!fullName &&
			type === 'child-screening' &&
			childSelfStarting &&
			distributedAt
		) {
			fullName = email || recipient || null;
		}
		let emailInviteRef;

		return (
			<tr className="lit-test" key={testKey}>
				{!this.props.groupPanel && (
					<td className="lit-test__col">
						<div className="checkbox-wrapper-13">
							<input
								type="checkbox"
								className="user_checkbox2"
								id="c1-13"
								defaultChecked={false}
								key={testKey}
								onChange={(e) => {
									if (e.target.checked) {
										this.props.onGettingTestKey(testKey);
									} else {
										this.props.onRemovingTestKey(testKey);
									}
								}}
							/>
						</div>
					</td>
				)}
				{this.props.groupPanel && (
					<td className="lit-test__col">
						<div className="checkbox-wrapper-13" />
					</td>
				)}
				{this.getLabelWithStatus(
					completedAt,
					usedAt,
					distributedAt,
					status
				)}
				<td className="lit-test__col">{`${testKey}`}</td>

				<td className="lit-test__col">{TEST_TYPE_NAMES[type]}</td>
				{groupStatus && testResult.adminFirstName !== 'Ikke' && (
					<td className="lit-test__col">{`${testResult.adminFirstName} ${testResult.adminLastName}`}</td>
				)}
				{groupStatus && testResult.adminFirstName === 'Ikke' && (
					<td className="lit-test__col">
						<button
							className="lit-btn lit-btn--small bg-secondary-green"
							onClick={() => {
								this.changeTestOwnerShip(testKey);
							}}
						>
							{'Overta'}
						</button>
					</td>
				)}
				<td className="lit-test__col">
					{fullName || email || <b>{strings.notdistributed}</b>}
					&nbsp;
				</td>
				{status === NOT_STARTED &&
					Boolean(childSelfStarting) &&
					type === 'child-screening' && (
						<td className="lit-test__col">
							<div className="lit-test__field">
								<form
									className="lit-input inline"
									onSubmit={(e) => {
										this.sendCodeUsingEmail(
											e,
											emailInviteRef.value
										);
									}}
								>
									<input
										className="lit-input__field"
										id={`email-invite-${id}`}
										name={`emailAddress-${id}`}
										placeholder={strings.sendcodeemail}
										ref={(ref) => {
											emailInviteRef = ref;
										}}
										required
										type="email"
									/>
									<div className="lit-input inline">
										<button className="lit-btn lit-btn--tiny bg-primary">
											{strings.send}
										</button>
									</div>
								</form>
							</div>
						</td>
					)}
				{this.getTestDateInfo(
					completedAt,
					usedAt,
					distributedAt,
					childSelfStarting,
					pinCode,
					type,
					groupStatus,
					testKey,
					id
				)}
				{status === NOT_STARTED &&
					screeningTypesExceptChild.includes(type) && (
						<td className="lit-test__col">
							<div className="lit-test__field">
								<form
									className="lit-input inline"
									onSubmit={(e) => {
										this.sendCodeUsingEmail(
											e,
											emailInviteRef.value
										);
									}}
								>
									<input
										className="lit-input__field"
										id={`email-invite-${id}`}
										name={`emailAddress-${id}`}
										placeholder={strings.sendcodeemail}
										ref={(ref) => {
											emailInviteRef = ref;
										}}
										required
										type="email"
									/>
									<div className="lit-input inline">
										<button className="lit-btn lit-btn--tiny bg-primary">
											{strings.send}
										</button>
									</div>
								</form>
							</div>
						</td>
					)}
				{status === NOT_STARTED && type === 'child-experimental' && (
					<td className="lit-test__col">
						<div className="lit-test__field">
							<form
								className="lit-input inline"
								onSubmit={(e) =>
									this.sendCodeUsingEmail(
										e,
										emailInviteRef.value
									)
								}
							>
								<input
									className="lit-input__field"
									id={`email-invite-${id}`}
									name={`emailAddress-${id}`}
									placeholder={strings.sendcodeemail}
									ref={(ref) => {
										emailInviteRef = ref;
									}}
									required
									type="email"
								/>
								<div className="lit-input inline">
									<button className="lit-btn lit-btn--tiny bg-primary">
										{strings.send}
									</button>
								</div>
							</form>
						</div>
					</td>
				)}
				{status === NOT_STARTED && childTypes.includes(type) && (
					<td className="lit-test__col">
						<div className="lit-test__field">
							<a
								className={`lit-start-test-btn ${
									this.state.visitedLinks.includes(testKey)
										? 'link-visited'
										: 'link-not-visited'
								}`}
								onClick={() => {
									exchangeToken(testKey).then((token) => {
										window.open(
											`${CHILD_TEST_URL}?key=${testKey}&role=admin&token=${token}&type=${type}`,
											'_blank'
										);
										if (this.props.onStartTest) {
											this.props.onStartTest();
										}

										const visitedLinks = JSON.parse(
											localStorage.getItem('visitedLinks')
										);

										if (!visitedLinks.includes(testKey)) {
											const updatedLinks = [
												...visitedLinks,
												testKey,
											];

											localStorage.setItem(
												'visitedLinks',
												JSON.stringify(updatedLinks)
											);
											this.setState({
												visitedLinks: updatedLinks,
											});
										}
									});
								}}
							>
								{strings.starttest}
							</a>
						</div>
					</td>
				)}
				{status === NOT_STARTED &&
					!Boolean(childSelfStarting) &&
					type === 'child-screening' && (
						<td className="lit-test__col">
							<div className="lit-test__field">
								<button
									className="lit-btn lit-btn--tiny bg-primary"
									onClick={() => {
										this.props.onshowInfoPanel(
											testKey,
											id,
											pinCode
										);
									}}
								>
									{strings.fillOutInfo}
								</button>
							</div>
						</td>
					)}

				<td className="lit-test__col">
					<div className="lit-test__field">
						{this.renderButton(
							type,
							id,
							status,
							testKey,
							groupStatus
						)}
					</div>
				</td>

				{this.renderDeleteButton(
					usedAt,
					completedAt,
					testKey,
					id,
					status,
					groupStatus,
					distributedAt
				)}
			</tr>
		);
	}
}

export default TestRow;
