import React from 'react';
import './styles.scss';
import deleteGroupMember from 'api/deleteGroupMember';
import deleteGroup from 'api/deleteGroup';
import renameGroup from 'api/renameGroup';
import getAllGroups from 'api/getAllGroups';
import strings from 'utils/strings';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faPenToSquare,
	faTrash,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';
import Loader from 'components/Loader';

class Groups extends React.Component {
	constructor() {
		super();
		this.state = {
			groupData: [], //Stores all the groups related data fetched from the back-end
			focusedGroup: '', //Points to the group that is in focus when user clicks 'Endre-gruppen' button
			newName: '',
			testsForDumpingLoading: true,
		};
	}
	componentDidMount() {
		this.getAndSetAdminGroups();
	}
	deleteSelectedgroupmember(groupId, subsId) {
		deleteGroupMember(groupId, subsId).then(() => {
			this.getAndSetAdminGroups();
		});
	}
	deleteSelectedgroup(data) {
		var id = data.map((id) => {
			return id.groupId;
		});
		deleteGroup(id[0]).then(() => {
			this.getAndSetAdminGroups();
		});
	}
	renameSelectedgroup(id, name) {
		renameGroup(id, name).then(() => {
			this.getAndSetAdminGroups();
		});
	}
	getAndSetAdminGroups() {
		this.setState({
			testsForDumpingLoading: true,
		});
		getAllGroups().then((groupData) => {
			this.setState({
				groupData,
				testsForDumpingLoading: false,
			});
		});
	}
	render() {
		const {groupData, focusedGroup, testsForDumpingLoading} = this.state;
		const groups = groupData.map((group) => {
			return group.groupName;
		});
		const uniqueGroups = [...new Set(groups)]; //creatng an array of all groups
		//singleGroup and allGroups are used to map unique groups to their corresponding group members data
		let singleGroup = [];
		const allGroups = [];
		let isFeide = false;
		//creating array objects by mapping group members to groups
		for (let i = 0; i < uniqueGroups.length; i++) {
			for (let j = 0; j < this.state.groupData.length; j++) {
				if (uniqueGroups[i] === this.state.groupData[j].groupName) {
					isFeide = this.state.groupData[j].isFeide;
					singleGroup.push({
						firstName: this.state.groupData[j].firstName,
						lastName: this.state.groupData[j].lastName,
						email: this.state.groupData[j].email,
						organization: this.state.groupData[j].organization,
						createdAt: this.state.groupData[j].created_at,
						subsId: this.state.groupData[j].subscription,
						groupId: this.state.groupData[j].Id,
						groupData: [],
					});
				}
			}
			allGroups.push({name: uniqueGroups[i], singleGroup, isFeide});

			singleGroup = [];
		}
		return (
			<div className="lit-groups">
				{testsForDumpingLoading && <Loader />}
				{allGroups.map((obj) => {
					if (!obj.singleGroup.length) {
						return null;
					}
					const feideLabel =
						obj.isFeide === true ? (
							<span
								style={{
									marginLeft: '7px',
									color: '#0f4256',
									fontWeight: 'bold',
									fontSize: '12px',
									marginRight: '16px',
								}}
							>
								( Feide )
							</span>
						) : (
							''
						);
					return (
						<div className="Groups" key={obj.name}>
							<div className="lit_group-heading">
								<div className="inline-btn">
									<h3>{obj.name}</h3>
									{feideLabel}
									<button
										className="lit-mytests-header-mytest-icon-btn-primary"
										title="Edit Group"
										onClick={() => {
											this.setState({
												focusedGroup: obj.name,
											});
										}}
									>
										<FontAwesomeIcon
											size="lg"
											className="lit-mytests-header-mytest-icon-btn-icon"
											icon={faPenToSquare}
										/>
									</button>
									<button
										className="lit-mytests-header-mytest-icon-btn-ternary"
										title="Delete Group"
										onClick={() => {
											if (
												window.confirm(
													strings.warning_deletegroup
												)
											) {
												this.deleteSelectedgroup(
													obj.singleGroup
												);
											}
										}}
									>
										<FontAwesomeIcon
											size="lg"
											className="lit-mytests-header-mytest-icon-btn-icon"
											icon={faTrash}
										/>
									</button>
								</div>

								<div className="delete_Group">
									{focusedGroup === obj.name && (
										<div className="lit-edit-group-container">
											<div className="inline-textFiled2">
												<input
													type="text"
													className="lit-tf_search"
													placeholder={obj.name}
													required
													autoFocus
													onChange={(e) => {
														this.setState({
															newName:
																e.target.value,
														});
													}}
												/>
											</div>
											<div className="inline-btn">
												<button
													className="lit-mytests-header-mytest-icon-btn-secondary"
													disabled={
														!this.state.newName
															.length
													}
													onClick={() => {
														this.renameSelectedgroup(
															obj.singleGroup[0]
																.groupId,
															this.state.newName
														);
													}}
												>
													<FontAwesomeIcon
														size="lg"
														className="lit-mytests-header-mytest-icon-btn-icon"
														icon={faCheck}
													/>
												</button>
											</div>
											<div className="inline-btn">
												<button
													className="lit-mytests-header-mytest-icon-btn-ternary"
													onClick={() => {
														this.setState({
															focusedGroup: '',
														});
													}}
												>
													<FontAwesomeIcon
														size="lg"
														className="lit-mytests-header-mytest-icon-btn-icon"
														icon={faXmark}
													/>
												</button>
											</div>
										</div>
									)}
								</div>
							</div>
							<table className="lit-list-user-list">
								<thead>
									<tr>
										<th className="lit-list-users-new-group-header">
											{strings.firstname}
										</th>
										<th className="lit-list-users-new-group-header">
											{strings.lastname}
										</th>
										<th className="lit-list-users-new-group-header">
											{strings.email}
										</th>
										<th className="lit-list-users-new-group-header">
											{strings.organization}
										</th>
										<th className="lit-list-users-new-group-header">
											{strings.date}
										</th>
										{focusedGroup === obj.name && <th />}
									</tr>
								</thead>
								<tbody>
									{obj.singleGroup.map(
										(memberdata, index) => {
											const timeCreated = moment(
												memberdata.createdAt
											);

											return (
												<tr
													key={index}
													className="lit-list__item"
												>
													<td className="lit-list-users-new-group-col">
														{memberdata.firstName}
													</td>
													<td className="lit-list-users-new-group-col">
														{memberdata.lastName}
													</td>

													<td className="lit-list-users-new-group-col">
														{memberdata.email}
													</td>
													<td className="lit-list-users-new-group-col">
														{
															memberdata.organization
														}
													</td>
													<td className="lit-list-users-new-group-col">
														{moment(
															timeCreated
														).format('LL')}
													</td>
													{focusedGroup ===
														obj.name && (
														<td className="lit-list-users-new-group-col">
															<button
																className="lit-btn lit-btn--small bg-negative2"
																onClick={() => {
																	if (
																		window.confirm(
																			strings.warning_deletemember
																		)
																	) {
																		this.deleteSelectedgroupmember(
																			memberdata.groupId,
																			memberdata.subsId
																		);
																	}
																}}
															>
																{strings.delete}
															</button>
														</td>
													)}
												</tr>
											);
										}
									)}
								</tbody>
							</table>
						</div>
					);
				})}
			</div>
		);
	}
}
export default Groups;
